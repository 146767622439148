import { mediaQueries } from '@loft/react-credpago';
import { color } from '@loft/tokens-credpago';
import { globalCss } from '@stitches/react';

export const globalStyles = globalCss({
  '*, *::before, *::after': {
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
  },
  body: {
    fontFamily: 'Roboto, sans-serif',
    display: 'flex',
    flexDirection: 'column',
    fontSize: '16px',
    textRendering: 'optimizeLegibility',
    color: color.text.primary,
    backgroundColor: color.background.secondary,
    minHeight: 'fill-available',
    [mediaQueries.md.down]: {
      backgroundColor: color.background.primary,
    },
  },
  img: {
    display: 'block',
    maxWidth: '100%',
    height: 'auto',
  },
  ul: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  a: {
    textDecoration: 'none',
    color: 'inherit',
  },
  '.Copan_Tooltip > p': {
    whiteSpace: 'pre',
  },
});
