export function getStorage<T>(
  key: string,
  options?: { parse?: boolean; type: 'local' | 'session' },
): T {
  const { type, parse } = options;
  if (typeof window !== 'undefined') {
    const value =
      type === 'local' ? localStorage.getItem(key ?? '') : sessionStorage.getItem(key ?? '');
    return parse ? JSON.parse(value ?? '{}') : value;
  }
}
