import type { LangDefinition } from '@empire-libs/intl';

const checkout: LangDefinition = {
  'checkout.page.title': 'Checkout',
  'checkout.page.safe.payment': 'Seu pagamento está 100% seguro',
  'checkout.page.product.name.table.head': 'Serviço',
  'checkout.page.product.price.table.head': 'Valor',
  'checkout.page.product.price.formatted-value': 'R$ {value}',
  'checkout.page.product.total-value': 'Total',
  'checkout.page.payment-method.title': 'Como deseja pagar?',
  'checkout.page.payment-method.continue.button': 'Continuar',
  'checkout-pay.page.title': 'Escolha um cartão para efetuar o pagamento',
  'checkout.page.status.paid.title': 'Parabéns!\nSeu pagamento foi concluído.',
  'checkout.page.status.paid.alternative.title': 'Pagamento Efetuado',
  'checkout.page.status.paid.alternative.subtitle':
    'Você será informado quando seu contrato for ativado.  ',
  'checkout.page.status.failed.payment.title': 'Erro ao processar o pagamento',
  'checkout.page.status.paid.subtitle': 'Você será informado quando seu contrato for ativado.',
  'checkout.page.status.undefined.title': 'Página não encontrada',
  'checkout.page.status.undefined.subtitle':
    'A página que você tentou acessar não existe mais ou não está disponível.',
  'checkout.page.status.canceled.title': 'Boleto cancelado',
  'checkout.page.status.canceled.subtitle': 'O boleto que você tentou acessar foi cancelado.',
  'checkout.page.status.failed.title': 'Erro no pagamento',
  'checkout.page.status.failed.subtitle':
    'Ocorreu um problema com o seu pagamento.\nEntre em contato para mais detalhes.',
  'checkout.page.status.error.title': 'Erro Inesperado',
  'checkout.page.status.error.subtitle':
    'Ocorreu um problema inesperado.\nEntre em contato para mais detalhes, ou tente novamente mais tarde.',
  'checkout.page.status.overdue.title': '{paymentType} vencido',
  'checkout.page.status.overdue.dueAt': `Data de vencimento: {dueAt}`,
  'checkout.page.status.overdue.subtitle':
    'A data de vencimento do {paymentType} expirou e não é possível efetuar pagamentos.',
  'checkout.page.summary.title': 'Descrição do pagamento',
  'checkout.page.summary.secondColumn': 'Valor',
  'checkout.page.status.pending.title': 'Aguardando o seu pagamento',
  'checkout.page.summary.main.title': 'Resumo',
  'checkout.page.payments.credit.title': 'Cartão de crédito',
  'checkout.page.payments.boleto.title': 'Boleto',
  'checkout.page.payments.pix.title': 'Pix',
  'checkout.page.payments.boleto.content':
    'Imprima o Boleto Bancário ou copie o código para efetuar o pagamento.\n\nA confirmação de pagamento via Boleto pode ocorrer em até <strong>6 dias úteis</strong>, conforme compensação bancária.',
  'checkout.page.payments.payment-confirmation.title': 'Revisar pagamento',
  'checkout.page.pending.payment.title': 'Pagamento pendente',
  'checkout.payment.title': 'Pagamento',
  'checkout.payment.change.method.link': 'Alterar forma de pagamento',
  'checkout.status.canceled.title': 'Cobrança cancelada',
  'checkout.status.canceled.subtitle': 'Nenhuma cobrança foi realizada.',
};

export default checkout;
