import type { PropsWithChildren, ReactNode } from 'react';
import React, { createContext, useMemo, useState } from 'react';
import type { FormData } from '@/components/Checkout/CreditCard/components/CreditCardForm';
import type { InstallmentsByProduct } from '@/components/Checkout/CreditCard/components/MultiChargeChoice';

type CreditCardContextType = {
  formData: FormData;
  setFormData: (param: FormData | ((param: FormData) => void)) => void;
  creditCardPaymentErrorMessage: string;
  setCreditCardPaymentErrorMessage: (value: string) => void;
  formStep: Steps | string;
  setFormStep: (value: Steps | string) => void;
  selectedInstallments: InstallmentsByProduct[];
  setSelectedInstallments: (value: InstallmentsByProduct[]) => void;
  selectInstallment(installmentByProduct: InstallmentsByProduct): void;
};

export type Steps = 'initial' | 'form' | 'multi-charge' | 'payment-confirmation';

const CreditCardContext = createContext({} as CreditCardContextType);

export function CreditCardProvider({
  children,
}: PropsWithChildren<JSX.Element | Element | ReactNode>) {
  const [formData, setFormData] = useState<FormData>(null);
  const [creditCardPaymentErrorMessage, setCreditCardPaymentErrorMessage] = useState('');
  const [formStep, setFormStep] = useState<Steps | string>('form');
  const [selectedInstallments, setSelectedInstallments] = useState([] as InstallmentsByProduct[]);

  function selectInstallment(installmentByProduct: InstallmentsByProduct) {
    setSelectedInstallments(prevState => {
      const installments = [...prevState];
      const index = installments.findIndex(
        item => item.productId === installmentByProduct.productId,
      );
      if (index === -1) {
        installments.push(installmentByProduct);
      } else {
        installments[index] = installmentByProduct;
      }
      return installments;
    });
  }

  const values = useMemo(
    () => ({
      formData,
      setFormData,
      creditCardPaymentErrorMessage,
      setCreditCardPaymentErrorMessage,
      formStep,
      setFormStep,
      selectedInstallments,
      setSelectedInstallments,
      selectInstallment,
    }),
    [
      formData,
      setFormData,
      creditCardPaymentErrorMessage,
      setCreditCardPaymentErrorMessage,
      formStep,
      setFormStep,
      selectedInstallments,
      setSelectedInstallments,
      selectInstallment,
    ],
  );

  return <CreditCardContext.Provider value={values}>{children}</CreditCardContext.Provider>;
}

export function useCreditCard() {
  const context = React.useContext(CreditCardContext);

  if (!context) {
    throw new Error('useCreditCard must be used within a CreditCardProvider');
  }

  return context;
}
