import type { LangDefinition } from '@empire-libs/intl';

const openCheckout: LangDefinition = {
  'openCheckout.value.toBePaid.title': 'Valor do {type}:',
  'openCheckout.pix.submitButton.label': 'Gerar código pix',
  'openCheckout.boleto.submitButton.label': 'Gerar boleto para pagamento',
  'openCheckout.creditCard.submitButton.label': 'Confirmar pagamento',
  // Boleto open checkout messages
  'openCheckout.boleto.first.paragraph':
    '1- O boleto estará disponível assim que você clicar em concluir a escolha do pagamento.',
  'openCheckout.boleto.second.paragraph':
    '2 - Imprima o Boleto Bancário ou copie o código para efetuar o pagamento.',
  'openCheckout.boleto.warning.paragraph':
    'A confirmação de pagamento via Boleto pode ocorrer em até 6 dias úteis, conforme compensação bancária.',
  // Pix open checkout messages
  'openCheckout.pix.first.paragraph':
    'Após confirmar o pagamento, o código Pix ficará disponível para você pagar no banco da sua preferência.',
};

export default openCheckout;
