import { useIntl } from '@empire-libs/intl';
import { Typography } from '@loft/react-credpago';
import React from 'react';
import { FooterContainer } from './styles';

export function Footer(): JSX.Element {
  const fullYear = new Date().getFullYear();
  const { formatMessage } = useIntl();
  return (
    <FooterContainer alignItems="center" justifyContent="center">
      <Typography textStyle="paragraph-sm-bold" modifier="highlight">
        {formatMessage({ id: 'common.app.title' })} / {formatMessage({ id: 'common.app.subtitle' })}{' '}
        © {fullYear}.
      </Typography>
    </FooterContainer>
  );
}
