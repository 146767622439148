import { __assign } from "tslib";
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
var DATADOG_SITE = 'datadoghq.com';
var DATADOG_SAMPLE_RATE = 30;
/**
 * Make monitoring setup for browser.
 *
 * @remarks
 * This method is part of the {@link https://github.com/loft-br/empire/libs/logger @empire-lib/monitoring} which is part of a set of {@link https://github.com/loft-br/empire/libs Empire libs}.
 *
 * @param configuration - The configuration is the of the official libraries {@link https://docs.datadoghq.com/real_user_monitoring/browser/#configuration Browser Run} and {@link https://docs.datadoghq.com/logs/log_collection/javascript/?tab=npm#configuration Browser Logs}
 *
 * @example
 * Put this call at your Root Componet:
 * ```tsx
 * // pages/_app.ts
 * import setupBrowserMonitoring from '@empire-libs/monitoring/browser'
 * function MyApp({ Component, pageProps }: AppProps) {
 *   const dataDogApplicationId = '<YOUR_APPLICATION_ID>';
 *   const dataDogClientToken = '<YOUR_APPLICATION_TOKEN>';
 *   const serviceName = 'teste';
 *   setupBrowserMonitoring({
 *      datadogConfig: {
 *        shared: {
 *          nodeEnv: 'sandbox',
 *          serviceName,
 *          dataDogClientToken,
 *          dataDogApplicationId
 *        },
 *      }
 *    })
 *   return <Component {...pageProps} />
 * }
 * ```
 * @see {@link http://example.com/@internal Documentation}
 */
function setupBrowserMonitoring(_a) {
    var _b = _a.datadogConfig, shared = _b.shared, rum = _b.rum, logs = _b.logs;
    var sharedParams = __assign(__assign({}, shared), { sessionSampleRate: typeof shared.sessionSampleRate === 'number' ? shared.sessionSampleRate : DATADOG_SAMPLE_RATE, site: DATADOG_SITE });
    datadogRum.init(__assign(__assign(__assign({}, sharedParams), rum), { trackUserInteractions: true, allowedTracingOrigins: [/https:\/\/.*\.loft\.com\.br/], sessionReplaySampleRate: 0 }));
    datadogLogs.init(__assign(__assign(__assign({}, sharedParams), logs), { forwardErrorsToLogs: true }));
}
export function setSlackChannel(slackChannel) {
    datadogRum.addRumGlobalContext('slackChannel', slackChannel);
    datadogLogs.addLoggerGlobalContext('slackChannel', slackChannel);
}
export default setupBrowserMonitoring;
