import type { LangDefinition } from '@empire-libs/intl';

const wallet: LangDefinition = {
  // card summary
  'wallet.card-summary.prefix': 'Final do cartão',
  // add card
  'wallet.add-card.title': 'Adicionar novo cartão à carteira',
  'wallet.add-card.number.label': 'Número do cartão',
  'wallet.add-card.holder-name.label': 'Nome impresso no cartão',
  'wallet.add-card.holder-document.label': 'CPF do responsável pelo cartão',
  'wallet.add-card.month.label': 'Mês',
  'wallet.add-card.year.label': 'Ano',
  'wallet.add-card.cvv.label': 'CVV',
  'wallet.add-card.submit.label': 'Adicionar cartão',
};

export default wallet;
