import type { LangDefinition } from '@empire-libs/intl';

const closedCheckout: LangDefinition = {
  'closedCheckout.page.title': 'Confirmação de pagamento',
  'closedCheckout.payment.method.change.title':
    'Você tem certeza que deseja alterar \n a forma de pagamento?',
  'closedCheckout.payment.method.change.body':
    'Caso já tenha efetuado o pagamento do \n boleto não altere para outra forma de \n pagamento e entre em contato com nosso \n time de atendimento para obter ajuda.',
};

export default closedCheckout;
