import type { PropsWithChildren, ReactNode } from 'react';
import React, { useCallback, useMemo, useState } from 'react';

type CopyTextContextType = {
  isCopied: boolean;
  setIsCopied: (value: boolean) => void;
  handleCopy: (code: string) => void;
};

const CopyTextContext = React.createContext({} as CopyTextContextType);

export function CopyTextProvider({
  children,
}: PropsWithChildren<JSX.Element | Element | ReactNode>) {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = useCallback((code: string) => {
    setIsCopied(true);

    navigator.clipboard.writeText(code);

    const time = setTimeout(() => {
      setIsCopied(false);
    }, 1500);
    return () => clearTimeout(time);
  }, []);

  const values = useMemo(
    () => ({
      isCopied,
      setIsCopied,
      handleCopy,
    }),
    [isCopied, setIsCopied, handleCopy],
  );

  return <CopyTextContext.Provider value={values}>{children}</CopyTextContext.Provider>;
}

export function useCopyText() {
  const context = React.useContext(CopyTextContext);

  if (!context) {
    throw new Error('useCopyText must be used within a CopyTextProvider');
  }

  return context;
}
