import type { LangDefinition } from '@empire-libs/intl';

const common: LangDefinition = {
  'common.app.title': 'Loft ',
  'common.app.subtitle': 'Fiança aluguel',
  'common.copied.code': 'Código copiado com sucesso!',
  'common.page.not.found.title': 'Página não encontrada',
  'common.link.not.found.subtitle':
    'A página que você tentou acessar não existe mais ou não está disponível.',
  'common.page.payment.confirmation.title': 'Confirmação de pagamento',
  'common.page.failed.payment.confirmation.title': 'Erro no pagamento',
  'common.call.center.title': 'Central de atendimento',
  'common.whatsapp.title': 'WhatsApp',
  'common.page.refunded.payment.confirmation.title': 'Pagamento estornado',
};

export default common;
