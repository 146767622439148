import { Grid, mediaQueries } from '@loft/react-credpago';
import { spacing } from '@loft/tokens-credpago';
import { styled } from '@stitches/react';

export const LayoutMain = styled('main', {
  flex: 1,
  [mediaQueries.md.up]: {
    padding: spacing.layout.base02,
  },
});

export const PaymentGrid = styled(Grid, {
  gap: 0,
  [mediaQueries.md.up]: {
    columnGap: spacing.layout.base03,
  },
  [mediaQueries.md.down]: {
    '> div:nth-of-type(1)': {
      order: 2,
    },
    '> div:nth-of-type(2)': {
      order: 1,
    },
    '> div:nth-of-type(3)': {
      order: 3,
    },
  },
});
