import { useRouter } from 'next/router';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useStoredDocument } from '@/hooks/useStoredDocument';
import { decodeBase64ToString } from '@/utils/decodeBase64ToString';

type AuthContextType = {
  isAuthenticated: boolean;
  setDocument: (document: string) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
  document: string | null;
};

const authContextValues = {} as AuthContextType;

const AuthContext = createContext(authContextValues);

export function AuthProvider({ children }: { readonly children: React.ReactNode }) {
  const {
    isReady,
    query: { id, token, request },
    replace,
    basePath,
  } = useRouter();

  const queryId = id as string;

  let queryToken: string;

  if (isReady && token !== undefined) {
    queryToken = decodeBase64ToString(token as string);
  }

  const requestToken = request as string;

  const { setStoredDocument } = useStoredDocument(queryId ?? requestToken);

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [document, setDocument] = useState<string>(null);

  useEffect(() => {
    if (isAuthenticated) {
      if (document) {
        setStoredDocument(document);
      }
    } else if (queryToken !== undefined) {
      setStoredDocument(queryToken);
      if (requestToken === undefined) {
        void replace(`${basePath}?id=${queryId}`);
      }
    }
  }, [isAuthenticated, document, queryId, queryToken, requestToken, setStoredDocument]);

  const values = useMemo(
    () => ({
      isAuthenticated,
      setDocument,
      setIsAuthenticated,
      document,
    }),
    [isAuthenticated, setDocument, document],
  );

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = React.useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
}
