import type { LangDefinition } from '@empire-libs/intl';

const pix: LangDefinition = {
  'pix.title': 'PIX',
  'pix.page.dueAt': 'Data de vencimento: {value}',
  'pix.page.info': 'Data de vencimento: {value}',
  'pix.page.scan.or.copy.message': 'Escaneie ou copie a chave pix',
  'pix.page.copy.message': 'Copiar o código PIX',
  'pix.page.view.qrcode': 'Visualizar o código QR',
  'pix.page.info.payment.delay':
    '<strong>Atenção: </strong>o prazo de pagamento\ncom o código é de <strong>30 minutos</strong>',
};

export default pix;
