import type { LangDefinition } from '@empire-libs/intl';

const login: LangDefinition = {
  'login.title': 'Que bom ter você aqui',
  'login.subtitle': 'Acesse sua conta e tenha controle de tudo o que acontece com o seu contrato.',
  'login.help.main.message': 'Precisa de ajuda?',
  'login.help.secondary.message': 'ou Whatsapp',
  'login.submit.button': 'Acessar',
};

export default login;
