import { Flex } from '@loft/react-credpago';
import type { ReactNode } from 'react';
import React from 'react';
import { Footer } from '@/components/common/Footer';
import { Navbar } from '@/components/common/Navbar';
import { LayoutMain } from './styles';

type LayoutProps = { readonly children: ReactNode };

function Layout({ children }: LayoutProps): JSX.Element {
  return (
    <Flex flexDirection="column" css={{ minHeight: '100vh' }} as="main">
      <Navbar />
      <LayoutMain>{children}</LayoutMain>
      <Footer />
    </Flex>
  );
}

export { Layout };
