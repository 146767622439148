import auth from './auth';
import boleto from './boleto';
import checkout from './checkout';
import closedCheckout from './closedCheckout';
import common from './common';
import creditCard from './creditCard';
import login from './login';
import openCheckout from './openCheckout';
import pix from './pix';
import wallet from './wallet';

export default {
  ...auth,
  ...boleto,
  ...checkout,
  ...common,
  ...creditCard,
  ...closedCheckout,
  ...login,
  ...openCheckout,
  ...pix,
  ...wallet,
};
