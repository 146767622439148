/* eslint-disable react/jsx-props-no-spreading */
import { LangProvider } from '@empire-libs/intl';
import setupBrowserMonitoring from '@empire-libs/monitoring/browser';
import Hotjar from '@hotjar/browser';
import type { AppProps } from 'next/app';
import getConfig from 'next/config';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { QueryClient, QueryClientProvider } from 'react-query';
import { CreditCardProvider } from '@/components/Checkout/contexts';
import { LoaderProvider } from '@/components/Checkout/contexts/LoaderProvider';
import { AuthProvider } from '@/components/contexts/AuthProvider';
import { Layout } from '@/components/Layout';
import defaultMessages from '@/lang/pt';
import { globalStyles } from '@/theme/globalStyles';
import { hotjarWorkaround } from '@/utils/hotjar';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const {
  publicRuntimeConfig: { gtmId, hotjarId, hotjarVersion, appEnv },
} = getConfig();

const tagManagerArgs = {
  gtmId,
};

function CustomApp({ Component, pageProps }: AppProps & { children: React.ReactNode }) {
  useEffect(() => {
    if (appEnv === 'production') {
      TagManager.initialize(tagManagerArgs);
      hotjarWorkaround();
      Hotjar.init(hotjarId, hotjarVersion);
      setupBrowserMonitoring({
        datadogConfig: {
          shared: {
            applicationId: '2b9e9882-4c08-46d7-af8e-97ec857f040b',
            clientToken: 'pub6ee5875fb3be9ce0bfbb9a8d80653df3',
            service: 'cp-checkout',
            sessionSampleRate: 100,
            env: appEnv,
          },
          rum: {
            site: 'us5.datadoghq.com',
          },
          logs: {
            site: 'us5.datadoghq.com',
          },
        },
      });
    }
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <LoaderProvider>
          <LangProvider locale="pt" defaultMessages={defaultMessages}>
            {globalStyles()}
            <Layout>
              <CreditCardProvider>
                <Component {...pageProps} />
              </CreditCardProvider>
            </Layout>
          </LangProvider>
        </LoaderProvider>
      </AuthProvider>
    </QueryClientProvider>
  );
}

export default CustomApp;
