import type { PropsWithChildren, ReactNode } from 'react';
import React, { useMemo, useState } from 'react';

type CopyTextContextType = {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
};

const CopyTextContext = React.createContext({} as CopyTextContextType);

export function LoaderProvider({ children }: PropsWithChildren<JSX.Element | Element | ReactNode>) {
  const [isLoading, setIsLoading] = useState(false);

  const values = useMemo(
    () => ({
      setIsLoading,
      isLoading,
    }),
    [isLoading, setIsLoading],
  );

  return <CopyTextContext.Provider value={values}>{children}</CopyTextContext.Provider>;
}

export function useLoader() {
  const context = React.useContext(CopyTextContext);

  if (!context) {
    throw new Error('useLoader must be used within a LoaderProvider');
  }

  return context;
}
