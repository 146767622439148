import type { LangDefinition } from '@empire-libs/intl';

const creditCard: LangDefinition = {
  'creditCard.title': 'Cartão de Crédito',
  'creditCard.title.card.only': 'Cartão',
  'creditCard.input.error': 'Dados Inválidos',
  'creditCard.name.label': 'Nome (como está no cartão)',
  'creditCard.owner.name.label': 'Nome completo',
  'creditCard.owner.name.placeholder': 'Nome completo do títular do cartão',
  'creditCard.owner.address.street.placeholder': 'Endereço do títular do cartão',
  'creditCard.owner.address.city.placeholder': 'Cidade do títular do cartão',
  'creditCard.owner.address.neighborhood.placeholder': 'Bairro do títular do cartão',
  'creditCard.owner.document': 'CPF',
  'creditCard.owner.zipcode': 'CEP',
  'creditCard.owner.address': 'Endereço',
  'creditCard.owner.address.number': 'Número',
  'creditCard.owner.address.complement': 'Complemento',
  'creditCard.owner.address.neighborhood': 'Bairro',
  'creditCard.owner.address.city': 'Cidade',
  'creditCard.owner.address.state': 'Estado',
  'creditCard.date.label': 'Vencimento',
  'creditCard.cardnumber.label': 'Número do cartão',
  'creditCard.cvv.label': 'CVV',
  'creditCard.payment-type.recurrence.label': 'Recorrencia',
  'creditCard.payment-type.recurrence.tooltip':
    'Essa opção <strong>NÃO COMPROMETE</strong> o limite do\nseu cartão.\nSimilar à assinaturas (Ex.: Netflix e Spotify).\nSe houver Setup, será cobrado\nem parcela(s) definida(s) pela imobiliária.',
  'creditCard.payment-type.installments.label': 'Parcelado',
  'creditCard.payment-type.installments.tooltip':
    'Nessa opção, o limite do seu cartão\n<strong>É COMPROMETIDO</strong>.\nSimilar a compras em lojas online.\nSe houver Setup, será cobrado\nem parcela(s) definida(s) pela imobiliária.',
  'creditCard.installments': 'parcela(s) de',
  'checkout.page.payments.multi-charge.title': 'Seu pagamento será dividido em {value}',
  'checkout.page.payments.multi-charge.alternative.title': 'Selecione a quantidade de parcelas',
  'creditCard.holder.checkbox.title': 'Declaro ser a pessoa titular do cartão.',
  'creditCard.holder.checkbox.tooltip': `A pessoa titular fica responsável por todas as transações e configurações\nrelacionadas a esse cartão, tendo controle sobre seu uso.`,
  'creditCard.new.card.title': 'Adicionar cartão',
  'creditCard.new.card.description':
    'Informe os dados do cartão para adicionar outra forma de pagamento.',
  'creditCard.new.card.submit.title': 'Atualizar cartão',
  'creditCard.new.card.add.success.title': `Cartão adicionado\ncom sucesso!`,
};

export default creditCard;
