import type { LangDefinition } from '@empire-libs/intl';

const boleto: LangDefinition = {
  'boleto.title': 'Boleto',
  'boleto.page.dueAt': 'Data de vencimento: {value}',
  'boleto.page.info': 'Data de vencimento: {value}',
  'boleto.page.copy.message': 'Copiar o código de barras',
  'boleto.page.view.pdffile': 'Visualizar PDF',
  'boleto.page.info.payment.delay':
    '<strong>Atenção: </strong>A confirmação de pagamento pode ocorrer \n em até <strong>6 dias úteis</strong>, conforme compensação bancária.',
};

export default boleto;
