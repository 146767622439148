import { decodeBase64ToString } from '@/utils/decodeBase64ToString';
import { encodeStringToBase64 } from '@/utils/encodeStringToBase64';
import { getStorage } from '@/utils/getStorage';
import { setStorage } from '@/utils/setStorage';
import { onlyNumbers } from '@/utils/unmaskString';

export type UseStoredDocumentResult = {
  storedDocument: string | null;
  setStoredDocument: (document: string) => void;
};

export function useStoredDocument(checkoutId: string): UseStoredDocumentResult {
  const authToken = getStorage<{ document: string; checkoutId: string } | null>('userAuth', {
    type: 'session',
    parse: true,
  });

  const storedDocument =
    authToken?.checkoutId && authToken?.checkoutId === checkoutId
      ? onlyNumbers(decodeBase64ToString(authToken.document))
      : null;

  function setStoredDocument(document: string) {
    const encodedDocument = encodeStringToBase64(onlyNumbers(document));

    setStorage(
      'userAuth',
      {
        checkoutId,
        document: encodedDocument,
      },
      { stringify: true },
    );
  }

  return {
    storedDocument,
    setStoredDocument,
  };
}
